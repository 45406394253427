/*~~~~~~~~~~~~~~~~~*/
/* AHAMELERS STYLE */
/*~~~~~~~~~~~~~~~~~*/

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400italic,600italic);
@import 'reset';

html, body, #body {
  height:  100%;
  box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
  text-align: center;
  font-family: 'Source Sans Pro', "Helvetica Neue", Helvetica, sans-serif;
}

h1, h2, h3, h4, h5, .bold, b, strong{
  font-weight:600;
}

h1, h2, h3, h4, h5 {
  margin: 1.5em auto .75em;
}

p {
  margin: 1em auto;
  line-height: 150%;
}

.center {
  text-align:center;
}

.italic {
  font-style:italic;
}

ul {
  margin: 1em auto;
  padding-left: 2em;
}

li {
   margin: .5em auto;
}

a:link {
  color: #008000;
}

a:visited {
  color: #008000;
}

a:hover {
  text-decoration: underline;
  color: #00b300;
}

a:active {
  color: #000;
}

header {
  max-width:400px;
  margin: 0 auto;
  padding-top: 30px;
  padding-right: 25px;

  a:link {
    text-decoration: none;
  }
  a img{
    float:left; 
    width:130px; 
    margin:0;
  }
  h1{
    margin-top:0px;
    padding-top:35px;
    font-size: 2em;
  }

  &::after {
    content: '';
    display:block;
    clear:both;
  }

  @media screen and (max-width:360px) {
    padding-right:0;
    a img {
      float:none;
      width: 150px;
    }
    h1 {
    padding-top:5px;
    }
  }
}

nav{
  max-width:400px;
  margin: -5px auto;

  a {
    margin:0px 15px;
    font-size:1.02em;
    letter-spacing:.02em;

    @media screen and (max-width:465px) {
      &:first-child {
        display: none;
      }
    }

    @media screen and (max-width:380px) {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}

main {
  max-width:750px;
  margin: 0px auto;
  text-align: left;

  & > h2 {
    margin-top: 0;
    text-align: center;
  }
}

img{
  border:0px;

  &.robot{
    width:150px;
    height: 210px;
    border-style: solid;
    border-color: #000000;
    border-width: 1px;
    margin: 15px;
    vertical-align: top;
  }
}

#body {
  font-size: 18px;
  font-size: 1.8rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
}

footer{
  font-size: .85em;
  max-width:720px;
  margin:0px auto;
  padding: 20px 0;
  flex-shrink: 0;
  clear: both;
}

#wrapper {
  flex:  1 0 auto;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

#box {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  grid-column-gap: 40px;
  grid-row-gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  h2 {
    margin-top: .5em;
  }

  .icons {
    a {
      text-decoration: none;
      border-bottom: 1px dotted #008000;
      opacity: .8;
      padding: 0 3px;

      img {
        width: 20px;
        width: 2rem;
        opacity: .8;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.intro {
  font-size: 1.15em;
}

.arch {
  background-color: #196719;
  color: #fff;
  font-size: .8em;
  border-radius: 2px;
  padding: 2px 4px;
  text-decoration: none;
  margin-left: 8px; 
}

iframe.contact_form {
  width: 100%;
  min-height: 100vh;
  height: 1200px;

  @media screen and (max-width: 500px) {
    height: 1400px;
    width: calc(100% + 60px);
    margin: 0 -30px;
  }
}

.dark {
  display: none;
}

@media (prefers-color-scheme: dark) {
  html, body, nav, main, footer{
    background-color: #000;
    color: #fff;
  }
  .dark {
    display: block;
  }
  .light {
    display: none;
  }

  a:link, a:visited {
    color: #00DB00;
  }

  a:hover {
    text-decoration: underline;
    color: #0FFF0F;
  }

  a:active {
    color: #fff;
  }

  .arch {
    color: 000;
    background-color: #00DB00;
  }

  #box .icons {
    a {
      border-bottom: 1px dotted #00DB00;
      opacity: 1;

      img { opacity: 1}

      &:hover {
        opacity: .8;
      }
    }
  }
}